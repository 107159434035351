var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-navigation-drawer',{attrs:{"expand-on-hover":"","color":"#f4f5fa","clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2",staticStyle:{"color":"#9ca3a5","font-size":"12px"}},[_vm._v(" UNOCRM | v4.0.32 "),_c('v-icon',[_vm._v(" mdi-settings")])],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{staticClass:"pl-0 pr-4 pt-3 sidebar",attrs:{"dense":""}},[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Dashboard")])],1)],1)]}}])},[_c('span',[_vm._v("Pantalla de inicio")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('reports')),expression:"permissions('reports')"}]},[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/reports"}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(" mdi-chart-bar")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Reportes")])],1)],1)]}}])},[_c('span',[_vm._v("Graficas mensuales y por día")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('clients')),expression:"permissions('clients')"}]},[_c('v-divider',{staticClass:"ma-4"}),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/clients","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(" mdi-account-multiple")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Clientes")])],1)],1)]}}])},[_c('span',[_vm._v("Lista de empresas a las que se les ha cotizado/vendido")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('influencers')),expression:"permissions('influencers')"}]},[_c('v-divider',{staticClass:"ma-4"}),_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/influencers","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v(" mdi-account-group")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Influencers")])],1)],1)]}}])},[_c('span',[_vm._v("Lista de influencers")])])],1),_c('v-divider',{staticClass:"ma-4"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('sales')),expression:"permissions('sales')"}]},[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/sales","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-currency-usd")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ventas")])],1)],1)]}}])},[_c('span',[_vm._v("Lista de ventas cerradas")])])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.permissions('cancelations')),expression:"permissions('cancelations')"}]},[_c('v-tooltip',{attrs:{"right":"","open-delay":"1000"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":"/canceled","link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-action',{staticClass:"mr-3"},[_c('v-icon',[_vm._v("mdi-close-circle-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cancelaciones")])],1)],1)]}}])},[_c('span',[_vm._v("Cotizaciones que no se cerraron por algun motivo")])])],1)],1)],1),_c('v-app-bar',{staticClass:"elevation-0 px-0",attrs:{"clipped-left":_vm.$vuetify.breakpoint.lgAndUp,"app":"","color":"#f4f5fa","dark":""}},[_c('v-icon',{staticClass:"mr-5",attrs:{"color":"grey"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_vm._v(" mdi-menu")]),_c('img',{staticStyle:{"height":"40px"},attrs:{"src":"/logo.png"}}),_c('v-spacer'),_c('v-btn',{staticClass:"hidden-sm-and-down pa-0",attrs:{"icon":"","to":"/","link":""}},[_c('v-icon',{attrs:{"color":"#707073"}},[_vm._v("mdi-apps")])],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2 mr-1",attrs:{"text":""}},'v-btn',attrs,false),on),[_c('v-avatar',{attrs:{"size":"32px"}},[_c('img',{attrs:{"src":"/avatar.png"}})]),_c('span',{staticClass:"hidden-sm-and-down",staticStyle:{"color":"#707073","margin-left":"10px","text-transform":"initial","letter-spacing":"0px","font-size":"16px","font-weight":"400"}},[_vm._v(" "+_vm._s(_vm.currentUser.name)+" "+_vm._s(_vm.currentUser.last))]),_vm._v(" "),_c('v-icon',{attrs:{"color":"#707073"}},[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary"}},[_c('v-list-item',{attrs:{"link":"","to":"/settings"}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" mdi-cog")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ajustes")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":_vm.logout}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-power")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Cerrar Sesión")])],1)],1)],1)],1)],1)],1),_c('v-main',{staticClass:"pa-0",staticStyle:{"background-color":"#f4f5fa"}},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('router-view',{on:{"closeDrawer":_vm.closeDrawer}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }